const ctxEventTypes = {
    PROCESSED: 0,
    DELIVERED: 1,
    DROPPED: 2,
    BOUNCED: 3,
    DEFERRED: 4,
    OPENED: 5,
    CLICKED: 6,
    UNSUBSCRIBED: 7,
    GROUP_UNSUBSCRIBED: 8,
    GROUP_RESUBSCRIBED: 9,
    SPAM_REPORT: 10
  };
  
  const exclusionStatus = {
    active: 0,
    inactive: 1
  };
  
  module.exports = {
    ctxEventTypes,
    exclusionStatus
  };
  