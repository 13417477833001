import React from "react";
import PropTypes from "prop-types";
import LeftsideCondensed from "./Leftside-Condensed.js";

const BottomCondensed = (props) => {
  const availableMarketList = props.availableMarketList.map( i => ({ value : i.Id, label : i.Name }) );
  return (
    <div className="bottom-component">
      <LeftsideCondensed
        unsubscribeFromLease={props.unsubscribeFromLease}
        unsubscribeFromSale={props.unsubscribeFromSale}
        unsubscribeFromAll={props.unsubscribeFromAll}
        handleCheckboxChange={props.handleCheckboxChange}
        handleMarketClick={props.handleMarketClick}
        handleRegionSelectionChange={props.handleRegionSelectionChange}
        forLeaseMarketSelections={props.forLeaseMarketSelections}
        forSaleMarketSelections={props.forSaleMarketSelections}
        availableMarketList={availableMarketList}
        updateSelectedMarkets={props.updateSelectedMarkets}
        updateSpaceUseArray={props.updateSpaceUseArray}
        propertyTypeOptionsArray={props.propertyTypeOptionsArray}
        propertyTypeArray={props.propertyTypeArray}
        updatePropertyTypeArray={props.updatePropertyTypeArray}
        spaceUseArray={props.spaceUseArray}
        spaceUseOptionsArray={props.spaceUseOptionsArray}
        type={props.type}
        showRegionSelect={props.showRegionSelect}
        handleSalePricePreferenceChange={props.handleSalePricePreferenceChange}
        salePricePreference={props.salePricePreference}
        handleUndisclosedCheckbox={props.handleUndisclosedCheckbox}
        spaceRange={props.spaceRange}
        updateSpaceRange ={ props.updateSpaceRange}
        includeUndisclosedSalePrice={props.includeUndisclosedSalePrice}
        onSave={props.onSave}
        saveButtonEnabled={props.saveButtonEnabled}
        handleTypeChange={props.handleTypeChange}
        starRating={props.starRating}
        updateStarRating={props.updateStarRating}
        formatMessage={props.formatMessage}
        //culture={props.culture}
        myContactPreferencesObject={props.myContactPreferencesObject}
        recipientExclusion={props.recipientExclusion}
      />
    </div>
  );
}

BottomCondensed.propTypes = {
  unsubscribeFromLease: PropTypes.bool.isRequired,
  unsubscribeFromSale: PropTypes.bool.isRequired,
  unsubscribeFromAll: PropTypes.bool.isRequired,
  showMarketModal: PropTypes.bool.isRequired,
  handleMarketClick: PropTypes.func.isRequired,
  handleRegionSelectionChange: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  onSelected: PropTypes.func.isRequired,
  updateSelectedMarkets: PropTypes.func.isRequired,
  updateSpaceUseArray: PropTypes.func.isRequired,
  propertyTypeOptionsArray: PropTypes.array.isRequired,
  propertyTypeArray: PropTypes.array.isRequired,
  updatePropertyTypeArray: PropTypes.func.isRequired,
  spaceUseArray: PropTypes.array.isRequired,
  spaceUseOptionsArray: PropTypes.array.isRequired,
  forLeaseMarketSelections: PropTypes.array.isRequired,
  forSaleMarketSelections: PropTypes.array.isRequired,
  type: PropTypes.string.isRequired,
  availableMarketList: PropTypes.array.isRequired,
  showRegionSelect: PropTypes.bool.isRequired,
  updateSpaceRange: PropTypes.func,
  onSave: PropTypes.func.isRequired,
  handleTypeChange: PropTypes.func,
  saveButtonEnabled: PropTypes.bool.isRequired,
  starRating: PropTypes.array,
  formatMessage: PropTypes.func,
  myContactPreferencesObject: PropTypes.object,
  recipientExclusion: PropTypes.bool
};

export default BottomCondensed;