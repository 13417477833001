import React from 'react';

class HeaderCondensed extends React.Component {

  render(){
    const { formatMessage } = this.props;

    return (
        <div className="header-component">
          <div className="header-top">
            <div className="header-component-heading">{formatMessage("listingMailPreferences")}</div>
          </div>
        </div>
    );
  }
}

export default HeaderCondensed;