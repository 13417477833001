import React from "react";
import PropTypes from "prop-types";
import { CountryCode } from "@costar/costar-enums";
// import costarLogo from '../content/images/logo-costar.svg';
// import loopnetLogo from '../content/images/logo-loopnet.svg';
// import belbexLogo from '../content/images/logo-belbex.svg';

const MastheadLogos = ({ currentCountry }) => {
  
  // const isCountry = (country, countryCode) =>
  //   Boolean(country && country.toUpperCase().substring(0, 2) === countryCode.substring(0, 2));

  // const isUK = isCountry(currentCountry, CountryCode.UnitedKingdom);
  // const isUSA = isCountry(currentCountry, CountryCode.UnitedStates);
  // const isUsaCan = isUSA || isCountry(currentCountry, CountryCode.Canada);
  // const isSpain = isCountry(currentCountry, CountryCode.Spain);

  return (
    <div className="masthead-center">
      <div className="masthead-logo logo-costar" />
      <div className="masthead-logo logo-loopnet" />
      {/* <img alt="" className="masthead-logo logo-costar" src={costarLogo} /> */}
      {/* {isSpain && (
        <img alt="" className="masthead-logo logo-belbex" src={belbexLogo} />
      )} */}
      {/* {isUsaCan && (
        <img alt="" className="masthead-logo logo-loopnet" src={loopnetLogo} />
      )} */}
      {/* {isUK &&
       <img alt="" className="masthead-logo logo-loopnet" src={loopnetLogo} />
       } */}
    </div>
  );
};

MastheadLogos.propTypes = {
  currentCountry: PropTypes.string.isRequired,
  isCountry: PropTypes.func
};

MastheadLogos.defaultProps = {
  currentCountry: CountryCode.UnitedStates
};

export { MastheadLogos };
export default MastheadLogos;
