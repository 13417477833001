
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import XHR from "i18next-xhr-backend";
import translationEnUS from "./language-packs/messages.en-US.json";
import translationEnGB from "./language-packs/messages.en-GB.json";
import translationEnCA from "./language-packs/messages.en-CA.json";
import translationFrCA from "./language-packs/messages.fr-CA.json";
import translationFrFR from "./language-packs/messages.fr-FR.json";

i18n
  .use(XHR)  
  .use(LanguageDetector)
  .init({
  // we init with resources
  resources: {
    "en-US": {
      subscription: translationEnUS
    },
    "en-GB": {
      subscription: translationEnGB
    },
    "en-CA": {
      subscription: translationEnCA
    },
    "fr-CA": {
      subscription: translationFrCA
    },
    "fr-FR": {
      subscription: translationFrFR
    },
  },
  fallbackLng: "en-US",
  debug: true,

  // have a common namespace used around the full app
  ns: ["subscription"],
  defaultNS: "subscription",

  keySeparator: false, // we use content as keys

  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },

  react: {
    wait: true
  }
});

export default i18n;