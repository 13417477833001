import React from 'react';
import PropTypes from 'prop-types';

class Checkbox extends React.Component {
  constructor(props){
    super(props);

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange() {
    this.props.handleCheckboxChange(this.props.name);
  }

  render(){
    return (
      <div className="checkbox-wrapper">
        <label className="checkbox-container">{this.props.text}
          <input 
            name={this.props.name} 
            type="checkbox" 
            checked={this.props.checked}
            onChange={this.handleChange}
          />
          <span className="checkmark"></span>
        </label>
      </div>
    );
  }
}

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired
}

export default Checkbox;