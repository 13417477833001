import React from "react";
import PropTypes from "prop-types";
import Checkbox from "./Checkbox";
import _ from "lodash";

const customStyles = {
  clearIndicator: (base, state) => {
    return {
      ...base,
      display: "none"
    };
  },
  control: (base, state) => {
    if (state.isFocused) {
      base["boxShadow"] = "0 0 4px 0 #0559B3";
      base["border"] = "1px solid #0559B3";
    } else {
      base["border"] = "1px solid #C5C5C5";
    }

    return {
      ...base,
      borderRadius: 1,
      "&:hover": {
        cursor: "pointer"
      }
    };
  },
  indicatorSeparator: (base, state) => {
    return {
      ...base,
      display: "none"
    };
  },
  menu: (base, state) => {
    return {
      ...base,
      marginTop: 0,
      border: "1px solid #0559B3",
      borderRadius: "0px 0px 3px 3px",
      boxShadow: "0 0 4px 0 #0559B3"
    };
  },
  multiValue: (base, state) => {
    return {
      ...base,
      borderRadius: 10,
      backgroundColor: "#F5F5F5",
      border: "1px solid #EAEAEA"
    };
  },
  multiValueRemove: (base, state) => {
    return {
      ...base,
      "&:hover": {
        backgroundColor: "#F5F5F5"
      }
    };
  },
  option: (base, state) => {
    return {
      ...base,
      "&:hover": {
        cursor: "pointer",
        backgroundColor: "#0559B3",
        color: "#FFF"
      }
    };
  }
};

const customStyles2 = _.clone(customStyles);

customStyles2.menuList = (base, state) => {
  return {
    ...base,
    overflowY: "scroll",
    "::-webkit-scrollbar": {
      webkitAppearance: "none",
      width: "7px"
    },
    "::-webkit-scrollbar-thumb": {
      background: "rgb(0,0,0, 0.6)",
      borderRadius: "6px"
    }
  }
};

class LeftsideCondensed extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption: null,
      spaceUseOptionsArray: [],
      selectMarketArray: [],
      salePrice: {}
    };

    this.handleSelectMarkets = this.handleSelectMarkets.bind(this);
    this.handleMarketSelectionChange = this.handleMarketSelectionChange.bind(this);
    this.handleSpaceUseSelectionChange = this.handleSpaceUseSelectionChange.bind(this);
    this.handleSalePriceSelectionChange = this.handleSalePriceSelectionChange.bind(this)
    this.handlePropertyTypeSelectionChange = this.handlePropertyTypeSelectionChange.bind(this);
    this.handleRegionChange = this.handleRegionChange.bind(this);
    this.determineIsRangeInValid = this.determineIsRangeInValid.bind(this)
    this.determineInvalidMessage = this.determineInvalidMessage.bind(this)
    this.handleSpaceRange = this.handleSpaceRange.bind(this);
  }

  componentWillReceiveProps(nextprops) {
    let selectedOption = [];

    const options = this.state.spaceUseOptionsArray;

    _(nextprops.spaceUseArray).forEach(function (val) {
      const foundValue = _.find(options, function (item) {
        const matchVal = val.value ? val.value : val;
        return item.value === matchVal;
      });

      if (typeof foundValue !== "undefined") {
        selectedOption.push(foundValue);
      }
    });

    const spaceUseOptionsArray = nextprops.spaceUseOptionsArray.map(obj => {
      return { value: obj.value, label: obj.label }
    })

    this.setState({
      selectedOption: selectedOption.slice(),
      spaceUseOptionsArray,
      spaceRange: nextprops.spaceRange
    });
  }

  handleSelectMarkets() {
    this.props.handleMarketClick();
  }


  determineIsRangeInValid(preferences) {
    if (!preferences || !preferences.min || !preferences.max) {
      return false
    }
    if (preferences.min && preferences.max) {
      return (Number.isInteger(preferences.min) && Number.isInteger(preferences.max) && preferences.min > preferences.max)
    }
    return false
  }

  determineInvalidMessage(min, max) {
    if (min === undefined || max === undefined) {
      return ""
    }
    if (min > max) {
      return "Min must be less than or equal to max."
    }
  }

  handleMarketSelectionChange(market) {
    this.props.updateSelectedMarkets(market);
  }

  handleSpaceUseSelectionChange(selectedOption) {
    this.setState(
      {
        selectedOption
      },
      () => {
        this.props.updateSpaceUseArray(selectedOption);
      }
    );
  }
  handleSalePriceSelectionChange(selectedOption) {
    this.setState(
      {
        selectedOption
      },
      () => {
        this.props.handleSalePricePreferenceChange(selectedOption);
      }
    );
  }

  handlePropertyTypeSelectionChange(propertyTypes) {
    this.props.updatePropertyTypeArray(propertyTypes);
  }

  handleRegionChange(region) {
    this.props.handleRegionSelectionChange(region);
  }

  handleSpaceRange(range) {
    let minEnteredValue = range.minValue;
    let maxEnteredValue = range.maxValue;
    let spaceRange = {
      "min": minEnteredValue,
      "max": maxEnteredValue
    }
    this.setState({ spaceRange: spaceRange }, () => {
      this.props.updateSpaceRange(spaceRange)
    })

  }

  handleTypeChange(type) {
    if(this.props.type !== type){
      this.props.handleTypeChange(type);
    }
  }

  render() {

    let saveButton = (
      <div className="header-spacing save-button-wrapper">
        <button
          className={!this.props.saveButtonEnabled ? 'disabled' : ''}
          disabled={!this.props.saveButtonEnabled}
          onClick={this.props.onSave}>
          {this.props.formatMessage("saveButton")}
        </button>
      </div>
    )

    const showRegularContents = (
        <div>
          { this.props.myContactPreferencesObject === undefined && 
            <>
            <Checkbox
              name="unsubscribeFromLease"
              checked={this.props.unsubscribeFromLease}
              handleCheckboxChange={this.props.handleCheckboxChange}
              text={this.props.formatMessage("unsubscribeLease")}
            />
          
            <Checkbox
              name="unsubscribeFromSale"
              checked={this.props.unsubscribeFromSale}
              handleCheckboxChange={this.props.handleCheckboxChange}
              text={this.props.formatMessage("unsubscribeSale")}
            />

            <Checkbox
              name="unsubscribeFromAll"
              checked={this.props.unsubscribeFromAll}
              handleCheckboxChange={this.props.handleCheckboxChange}
              text={this.props.formatMessage("unsubscribeAll")}
            />
          </>
          }
          { this.props.myContactPreferencesObject && 
            <Checkbox
              name="recipientExclusion"
              checked={this.props.recipientExclusion}
              handleCheckboxChange={this.props.handleCheckboxChange}
              text={this.props.formatMessage("unsubscribeAll")}
            />
          }
        </div>
      )
    // }

    return (
      <div className="leftside-component">
        <div className="leftside-content">
          {showRegularContents}
          {saveButton}
        </div>
      </div>
    );
  }
}

LeftsideCondensed.propTypes = {
  unsubscribeFromLease: PropTypes.bool.isRequired,
  unsubscribeFromSale: PropTypes.bool.isRequired,
  unsubscribeFromAll: PropTypes.bool.isRequired,
  handleCheckboxChange: PropTypes.func.isRequired,
  handleMarketClick: PropTypes.func.isRequired,
  handleRegionSelectionChange: PropTypes.func.isRequired,
  updateSelectedMarkets: PropTypes.func.isRequired,
  updateSpaceUseArray: PropTypes.func.isRequired,
  propertyTypeOptionsArray: PropTypes.array.isRequired,
  propertyTypeArray: PropTypes.array.isRequired,
  updatePropertyTypeArray: PropTypes.func.isRequired,
  spaceUseArray: PropTypes.array.isRequired,
  spaceUseOptionsArray: PropTypes.array.isRequired,
  forLeaseMarketSelections: PropTypes.array,
  forSaleMarketSelections: PropTypes.array,
  type: PropTypes.string.isRequired,
  showRegionSelect: PropTypes.bool.isRequired,
  spaceRange: PropTypes.object,
  updateSpaceRange: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  handleTypeChange: PropTypes.func,
  saveButtonEnabled: PropTypes.bool.isRequired,
  formatMessage: PropTypes.func,
  myContactPreferencesObject: PropTypes.object,
  recipientExclusion: PropTypes.bool
  //culture: PropTypes.string
};

export default LeftsideCondensed;
