import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import AutoNumeric from "autonumeric";
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import { fetchConfig } from './requests';
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n/i18n";

//making AutoNumeric globally available:
window.AutoNumeric = AutoNumeric;

// import registerServiceWorker from './registerServiceWorker';

// Wrapping the render of the app so that we can pass in config that is fetched from server
window.RenderApp = (configUrl) => {
  fetchConfig(configUrl).then((config) => {
    render((
      <BrowserRouter>
        <I18nextProvider i18n={i18n}>
          <App _config={config}/>
        </I18nextProvider>
      </BrowserRouter>
    ),
      document.getElementById('root')
    );
  })
}
// registerServiceWorker();
